import * as ScreenOrientation from "expo-screen-orientation";
import * as SplashScreen from "expo-splash-screen";
import React, { useEffect, useMemo, useState } from "react";
import {
  GestureResponderEvent,
  ImageBackground,
  LayoutChangeEvent,
  StyleSheet,
  TouchableWithoutFeedback,
  View,
} from "react-native";
import {
  GestureHandlerRootView,
  enableExperimentalWebImplementation,
} from "react-native-gesture-handler";
import { cacheFonts } from "./helper/cacheFonts";
import { cacheImages } from "./helper/cacheImages";
import BlobGLView from "./src/BlobGLView";
import { ctx } from "./src/BlobContext";
import {
  MenuState,
  aboutPresentation,
  fonts,
  images,
  onboardingJourney,
  presentationImages,
} from "./src/Constants";
import Gallery from "./src/Gallery";
import IFrameComponent from "./src/IFrameComponent";
import Menu from "./src/Menu";
import ModalComponent from "./src/ModalComponent";
import StateDebugger from "./src/StateDebugger";
import { VideoComponent } from "./src/VideoComponent";
import IdleTimeout from "./src/IdleTimeout";
// Enable native gestures on Web
enableExperimentalWebImplementation(true);
// Lock Screen Rotation
/* Apple added support for split view mode to iPads in iOS 9. This changed how the screen orientation is handled by the system.
 * To put the matter shortly, for iOS, your iPad is always in landscape mode unless you open two applications side by side.
 * To be able to lock screen orientation using this module you will need to disable support for this feature.
 */
ScreenOrientation.lockAsync(ScreenOrientation.OrientationLock.LANDSCAPE);

SplashScreen.preventAutoHideAsync();
// FIXME need reanimated update, see https://github.com/software-mansion/react-native-reanimated/issues/3355
if (window && !window._frameTimestamp) {
  // @ts-ignore
  window._frameTimestamp = null;
}

export default function App() {
  const [appIsReady, setAppIsReady] = useState(false);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [menuState, setMenuState] = useState<MenuState>(MenuState.Idle);
  const [modalOpen, setModalOpened] = useState<boolean>(false);
  const [modalContent, setModalContent] = useState<JSX.Element | null>(null);
  const [modalHasBorder, setModalHasBorder] = useState(true);

  // Preload assets
  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        const imageAssets = cacheImages(images);
        const fontAssets = cacheFonts(fonts);
        await Promise.all([...imageAssets, ...fontAssets]);
      } catch (e) {
        console.warn(e);
      } finally {
        SplashScreen.hideAsync();
        setAppIsReady(true);
      }
    }
    loadResourcesAndDataAsync();
  }, []);
  useEffect(() => {
    setModalOpened(
      ![MenuState.Idle, MenuState.Menu, MenuState.WorkForUs].includes(menuState)
    );
    switch (menuState) {
      // case MenuState.WhoWeAre:
      //   setModalHasBorder(false);
      //   setModalContent(<VideoComponent assetId={0} />);
      //   break;
      case MenuState.Video:
        setModalHasBorder(false);
        setModalContent(<VideoComponent assetId={1} />);
        break;
      case MenuState.Presentation:
        setModalHasBorder(true);
        setModalContent(<Gallery images={aboutPresentation} width={width} />);
        break;
      case MenuState.MarketPlace:
        setModalHasBorder(true);
        setModalContent(
          <IFrameComponent src="https://portal.int.cofinity-x.com/" />
        );
        break;
      case MenuState.Teaser:
        setModalHasBorder(false);
        setModalContent(<VideoComponent assetId={2} />);
        break;
      case MenuState.Journey:
        setModalHasBorder(true);
        setModalContent(<Gallery images={onboardingJourney} width={width} />);
        break;
      case MenuState.VirtualWorld:
        setModalHasBorder(false);
        setModalContent(<VideoComponent assetId={4} />)
        break;
      case MenuState.Registration:
        setModalHasBorder(false);
        setModalContent(<VideoComponent assetId={3} />)
        break;
      case MenuState.ContactUs:
        setModalHasBorder(true);
        setModalContent(
          <IFrameComponent src="https://www.cofinity-x.com/contact/" />
        );
        break;
      default:
        setModalContent(null);
        break;
    }
  }, [menuState]);

  function rerenderScene(event: LayoutChangeEvent) {
    setWidth(event.nativeEvent.layout.width);
    setHeight(event.nativeEvent.layout.height);
  }

  function onPress(event: GestureResponderEvent) {
    if (menuState != MenuState.Idle) return;

    setMenuState(MenuState.Menu);
    // TODO no action timeout
  }

  ctx.isActive = useMemo(() => {
    return [MenuState.Idle, MenuState.Menu, MenuState.WorkForUs].includes(
      menuState
    );
  }, [menuState]);

  if (!appIsReady) {
    return null;
  }

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <IdleTimeout setMenuState={setMenuState} menuState={menuState}>
        <TouchableWithoutFeedback
          onPress={onPress}
          style={styles.fullScreen}
          onLayout={rerenderScene}
        >
          <View style={styles.container}>
            <ImageBackground source={images[0]} style={styles.fullScreen} />
            {menuState === MenuState.Idle ? (
              <View
                style={{ position: "absolute", width: "100%", height: "100%" }}
              >
                <VideoComponent
                  assetId={1}
                  useNativeControls={false}
                  isMuted={true}
                  isLooping={true}
                />
              </View>
            ) : null}
            <BlobGLView />
            <Menu
              font={"KarbonMedium"}
              width={width}
              height={height}
              menuState={menuState}
              setMenuState={setMenuState}
            />
            {modalOpen ? (
              <ModalComponent
                hasBorder={modalHasBorder}
                width={width}
                height={height}
                setMenuState={setMenuState}
              >
                {modalContent}
              </ModalComponent>
            ) : null}
          </View>
        </TouchableWithoutFeedback>
      </IdleTimeout>
    </GestureHandlerRootView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "white",
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  },
  fullScreen: {
    width: "100%",
    height: "100%",
  },
});
