import { StyleSheet, View } from "react-native";
import React, { useEffect, useState } from "react";
import { MenuState } from "./Constants";
import { Gesture, GestureDetector } from "react-native-gesture-handler";
const IDLE_TIMEOUT = 5 * 60 * 1000; // 2 minutes
// const IDLE_TIMEOUT = 10 * 1000; // 10 Sekunden

interface IdleTimeoutProps {
  children: any;
  menuState: MenuState;
  setMenuState: React.Dispatch<React.SetStateAction<MenuState>>;
}

const IdleTimeout = (props: IdleTimeoutProps) => {
  const [idleTimer, setIdleTimer] = useState<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (props.menuState !== MenuState.Idle) {
      resetIdleTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.menuState]);
  useEffect(() => {
    resetIdleTimer();

    return () => {
      if (idleTimer != null) {
        clearTimeout(idleTimer);
      }
    };
  }, []);
  const resetIdleTimer = () => {
    if (idleTimer != null) {
      clearTimeout(idleTimer);
    }
    console.log('reset Timeout');
    setIdleTimer(
      setTimeout(() => {
        console.log("Idle Mode Activated");
        props.setMenuState(MenuState.Idle);
      }, IDLE_TIMEOUT)
    );
  };
  const gesture = Gesture.Tap()
    .runOnJS(true)
    .onStart(() => {
      resetIdleTimer();
    });
  return (
    <GestureDetector gesture={gesture}>
      <View style={styles.idleTimer}>{props.children}</View>
    </GestureDetector>
  );
};

export default IdleTimeout;

const styles = StyleSheet.create({
  idleTimer: {
    flex: 1,
  },
});
