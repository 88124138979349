import { BlobID, ctx } from "./BlobContext";
import { BlobScene } from "./Constants";


function BlobConfigBuilder(): any {
  
  const mainBlobSize = 1/24;
  const idleMainBlobSize = mainBlobSize * 0.9;
  const subBlobSize = 0.022;
  const subSubBlobSize = 0.008;
  const subSubSubBlobSize = 0.004;
  const hiddenBlobSize = 0.005;
  
  
  const mainBlobY = 0.18;
  const sub1BlobY = 0.30;
  const sub2BlobY = 0.42;
  
  const blobCenterX = 0.45;
  const blobCenterOffsetX = 0.28;

  const subSubOffsetY1 = 0.11;
  const subSubOffsetY2 = 0.09;
  const subSubSubOffsetY1 = 0.07;
  const subSubSubOffsetY2 = 0.06;

  const subSub11BlobY = sub1BlobY + subSubOffsetY1;
  const subSub12BlobY = sub1BlobY + subSubOffsetY1 + subSubOffsetY2;

  const subSub21BlobY = sub2BlobY + subSubOffsetY1;
  const subSub22BlobY = sub2BlobY + subSubOffsetY1 + subSubOffsetY2;

  const subSubSub11BlobY = subSub12BlobY + subSubSubOffsetY1;
  const subSubSub12BlobY = subSub12BlobY + subSubSubOffsetY1 + subSubSubOffsetY2;

  const subSubSub21BlobY = subSub22BlobY + subSubSubOffsetY1;
  const subSubSub22BlobY = subSub22BlobY + subSubSubOffsetY1 + subSubSubOffsetY2; 
  const subSubSub23BlobY = subSub22BlobY + subSubSubOffsetY1 + 2 * subSubSubOffsetY2;
  const subSubSub24BlobY = subSub22BlobY + subSubSubOffsetY1 + 3 * subSubSubOffsetY2;


  const idleX = 0.90;
  const idleY = 0.1;

  const idleToCenterTime = 1.6;
  const subBlobSplitTime = 0.15;
  const subSubBlobSplitTime = 0.12;

  let result: any = {
    idleX,
    idleY,

    idleMainBlobSize,
    mainBlobSize,
    subBlobSize,
    subSubBlobSize,
    subSubSubBlobSize,

    blobCenterX,
    blobCenterOffsetX,

    mainBlobY,
    sub1BlobY,
    sub2BlobY,

    subSub11BlobY,
    subSub12BlobY,
    subSub21BlobY,
    subSub22BlobY,
    subSubSub11BlobY,
    subSubSub12BlobY,
    subSubSub21BlobY,
    subSubSub22BlobY,
    subSubSub23BlobY,
    subSubSub24BlobY,

    idleToCenterTime,
    subBlobSplitTime,
    subSubBlobSplitTime,
  }

  
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ 
  // sequence of animation infos for every blob
  result[BlobScene.Idle] = [{
      time: 1.1,
      reverseTime: 1.1,
      target: []
    }
  ]

  let idleMainBlob = [idleX, idleY, idleMainBlobSize];
  let idleHiddenBlob = [idleX, idleY, subBlobSize];
  result[BlobScene.Idle][0].target[BlobID.Main] = idleMainBlob;
  for(let i = BlobID.About; i <= BlobID.WorkForAs; i++) {
    result[BlobScene.Idle][0].target[i] = idleHiddenBlob;
  }
  
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ 
  result[BlobScene.Menu] = [
    { // move to center
      time: 0.1,
      reverseTime: idleToCenterTime,
      target: []
    },
    { // split to 4 sub
      time: idleToCenterTime,
      reverseTime: 0.1,
      target: []
    },
  ]; 
  
  let centerMainBlob = [blobCenterX, mainBlobY, mainBlobSize];
  let centerSubBlob = [blobCenterX, mainBlobY, subBlobSize];
  let subLeftBlob = [blobCenterX - blobCenterOffsetX, sub1BlobY, subBlobSize];
  let subLeftHiddenBlob = [blobCenterX - blobCenterOffsetX, sub1BlobY, subSubBlobSize];
  let subCenterBlob = [blobCenterX, sub2BlobY, subBlobSize];
  let subCenterHiddenBlob = [blobCenterX, sub2BlobY, subSubBlobSize];
  let subRightBlob = [blobCenterX + blobCenterOffsetX, sub1BlobY, subBlobSize];
  let subRightHiddenBlob = [blobCenterX + blobCenterOffsetX, sub1BlobY, subSubBlobSize];

  let revBezierCtrl1 = [0.05, 0.03, 0.05, 0.03];
  let revBezierCtrl2 = [0.02, 0.15, 0.08, 0.05];
  let revBezierCtrl3 = [0.06, 0.12, 0.05, 0.02];
  let revBezierCtrl4 = [0.08, 0.10, 0.01, 0.06];

  let bezierCtrl1 = [-0.15, 0.30, -0.05, 0.08];
  let bezierCtrl2 = [-0.15, 0.15,  0.08, 0.10];
  let bezierCtrl3 = [-0.15, 0.25,  0.05, 0.10];
  let bezierCtrl4 = [-0.15, 0.20,  0.01,  0.05];
  // ~~~~~~~~~~~~~~~~ reverse animation ~~~~~~~~~~~~~~~~ 
  // [startX, startY, endX, endY];
  // let bezierCtrl1 = [-0.15, 0.30, -0.15, 0.15];
  // let bezierCtrl2 = [-0.15, 0.15, 0.05, 0.10];
  // let bezierCtrl3 = [-0.15, 0.25, -0.10, 0.10];
  // let bezierCtrl4 = [-0.15, 0.20, -0.1,  0.15];

  result[BlobScene.Menu][0].target[BlobID.Main] = [...idleMainBlob, ...revBezierCtrl1];
  
  result[BlobScene.Menu][0].target[BlobID.About] = [...idleHiddenBlob, ...revBezierCtrl2];
  for(let i = BlobID.Video; i <= BlobID.Presentation; i++) {
    result[BlobScene.Menu][0].target[i] = [...idleHiddenBlob, , ...revBezierCtrl2];
  }
  
  result[BlobScene.Menu][0].target[BlobID.Product] = [...idleHiddenBlob, ...revBezierCtrl3];
  for(let i = BlobID.Marketplace; i <= BlobID.Registration; i++) {
    result[BlobScene.Menu][0].target[i] = [...idleHiddenBlob, ...revBezierCtrl3];
  }
  
  result[BlobScene.Menu][0].target[BlobID.GetTouch] = [...idleHiddenBlob, ...revBezierCtrl4];
  for(let i = BlobID.ContactUs; i <= BlobID.WorkForAs; i++) {
    result[BlobScene.Menu][0].target[i] = [idleHiddenBlob, ...revBezierCtrl4];
  }

  
  // ~~~~~~~~~~~~~~~~ forward animation ~~~~~~~~~~~~~~~~ 

  // let bezierCtrl1 = [-0.15, 0.30, -0.15, 0.15];
  // let bezierCtrl2 = [-0.15, 0.15, 0.05, 0.10];
  // let bezierCtrl3 = [-0.15, 0.25, -0.10, 0.10];
  // let bezierCtrl4 = [-0.15, 0.20, -0.1,  0.15];

  result[BlobScene.Menu][1].target[BlobID.Main] = [...centerMainBlob, ...bezierCtrl1];
  
  result[BlobScene.Menu][1].target[BlobID.About] = [...subLeftBlob, ...bezierCtrl2];
  for(let i = BlobID.Video; i <= BlobID.Presentation; i++) {
    result[BlobScene.Menu][1].target[i] = [...subLeftHiddenBlob, , ...bezierCtrl2];
  }
  
  result[BlobScene.Menu][1].target[BlobID.Product] = [...subCenterBlob, ...bezierCtrl3];
  for(let i = BlobID.Marketplace; i <= BlobID.Registration; i++) {
    result[BlobScene.Menu][1].target[i] = [...subCenterHiddenBlob, ...bezierCtrl3];
  }
  
  result[BlobScene.Menu][1].target[BlobID.GetTouch] = [...subRightBlob, ...bezierCtrl4];
  for(let i = BlobID.ContactUs; i <= BlobID.WorkForAs; i++) {
    result[BlobScene.Menu][1].target[i] = [subRightHiddenBlob, ...bezierCtrl4];
  }

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ 
  // split left sub menu

  result[BlobScene.AboutConfinityX] = [ 
    { time: 0.1, reverseTime: subBlobSplitTime, timefunction: "linear", reverseTimefunction: "easeout", target: [] },
    { time: subBlobSplitTime, reverseTime: subBlobSplitTime, timefunction: "linear", reverseTimefunction: "linear", target: [] },
    { time: subBlobSplitTime, reverseTime: 0.1, timefunction: "easeout", reverseTimefunction: "linear", target: [] },
  ]; 

  for(let i = BlobID.Video; i <= BlobID.Presentation; i++) {
    result[BlobScene.AboutConfinityX][0].target[i] = subLeftHiddenBlob;
  }

  let subSubLeftBlob1 = [blobCenterX - blobCenterOffsetX, subSub11BlobY, subSubBlobSize];
  let subSubLeftHiddenBlob1 = [blobCenterX - blobCenterOffsetX, subSub11BlobY, hiddenBlobSize];
  result[BlobScene.AboutConfinityX][1].target[BlobID.Video] = subSubLeftBlob1;
  result[BlobScene.AboutConfinityX][1].target[BlobID.Presentation] = subSubLeftHiddenBlob1;
  
  
  let subSubLeftBlob2 = [blobCenterX - blobCenterOffsetX, subSub12BlobY, subSubBlobSize];
  result[BlobScene.AboutConfinityX][2].target[BlobID.Presentation] = subSubLeftBlob2;
  
  
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ 
  // split center sub menu

  result[BlobScene.ProductAndService] = [ 
    { time: 0.1, reverseTime: subBlobSplitTime, timefunction: "linear", reverseTimefunction: "easeout", target: [] },
    { time: subBlobSplitTime, reverseTime: subBlobSplitTime, timefunction: "linear", reverseTimefunction: "linear", target: [] },
    { time: subBlobSplitTime, reverseTime: 0.1, timefunction: "easeout", reverseTimefunction: "linear", target: [] },
  ]; 
  
  result[BlobScene.ProductAndService][0].target[BlobID.Product] = subCenterBlob;
  for(let i = BlobID.Marketplace; i <= BlobID.Registration; i++) {
    result[BlobScene.ProductAndService][0].target[i] = subCenterHiddenBlob;
  }
  
  let subSubCenterBlob1 = [blobCenterX, subSub21BlobY, subSubBlobSize];
  let subSubCenterHiddenBlob1 = [blobCenterX, subSub21BlobY, hiddenBlobSize];
  result[BlobScene.ProductAndService][1].target[BlobID.Marketplace] = subSubCenterBlob1;
  for(let i = BlobID.OnBoarding; i <= BlobID.Registration; i++) {
    result[BlobScene.ProductAndService][1].target[i] = subSubCenterHiddenBlob1;
  }
  
  let subSubCenterBlob2 = [blobCenterX, subSub22BlobY, subSubBlobSize];
  let subSubCenterHiddenBlob2 = [blobCenterX, subSub22BlobY, hiddenBlobSize];
  result[BlobScene.ProductAndService][2].target[BlobID.OnBoarding] = subSubCenterBlob2;
  for(let i = BlobID.Teaser; i <= BlobID.Registration; i++) {
    result[BlobScene.ProductAndService][2].target[i] = subSubCenterHiddenBlob2;
  }

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ 
  // split right sub menu

  result[BlobScene.GetInTouch] = [ 
    { time: 0.1, reverseTime: subBlobSplitTime, timefunction: "linear", reverseTimefunction: "easeout", target: [] },
    { time: subBlobSplitTime, reverseTime: subBlobSplitTime, timefunction: "linear", reverseTimefunction: "linear", target: [] },
    { time: subBlobSplitTime, reverseTime: 0.1, timefunction: "easeout", reverseTimefunction: "linear", target: [] },
  ]; 
  
  result[BlobScene.GetInTouch][0].target[BlobID.GetTouch] = subRightBlob;
  result[BlobScene.GetInTouch][0].target[BlobID.ContactUs] = subRightHiddenBlob;
  result[BlobScene.GetInTouch][0].target[BlobID.WorkForAs] = subRightHiddenBlob;
  
  let subSubRightBlob1 = [blobCenterX + blobCenterOffsetX, subSub11BlobY, subSubBlobSize];
  let subSubRightHiddenBlob1 = [blobCenterX + blobCenterOffsetX, subSub11BlobY, hiddenBlobSize];
  result[BlobScene.GetInTouch][1].target[BlobID.ContactUs] = subSubRightBlob1;
  result[BlobScene.GetInTouch][1].target[BlobID.WorkForAs] = subSubRightHiddenBlob1;

  
  let subSubRightBlob2 = [blobCenterX + blobCenterOffsetX, subSub12BlobY, subSubBlobSize];
  // result[BlobScene.GetInTouch][1].target[BlobID.WorkForAs] = subSubRightBlob2;
  result[BlobScene.GetInTouch][2].target[BlobID.WorkForAs] = subSubRightBlob2;


  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ 
  // split center sub sub menu

  result[BlobScene.OnBoarding] = [ 
    { time: 0.1, reverseTime: subSubBlobSplitTime, timefunction: "linear", reverseTimefunction: "easeout", target: [] },
    { time: subSubBlobSplitTime, reverseTime: subSubBlobSplitTime, timefunction: "linear", reverseTimefunction: "linear", target: [] },
    { time: subSubBlobSplitTime, reverseTime: subSubBlobSplitTime, timefunction: "linear", reverseTimefunction: "linear", target: [] },
    { time: subSubBlobSplitTime, reverseTime: subSubBlobSplitTime, timefunction: "linear", reverseTimefunction: "linear", target: [] },
    { time: subSubBlobSplitTime, reverseTime: 0.1, timefunction: "easeout", reverseTimefunction: "linear", target: [] },
  ]; 
  
  result[BlobScene.OnBoarding][0].target[BlobID.Teaser] = subSubCenterHiddenBlob2;
  result[BlobScene.OnBoarding][0].target[BlobID.Journey] = subSubCenterHiddenBlob2;
  result[BlobScene.OnBoarding][0].target[BlobID.VirtualWorld] = subSubCenterHiddenBlob2;
  result[BlobScene.OnBoarding][0].target[BlobID.Registration] = subSubCenterHiddenBlob2;
  
  result[BlobScene.OnBoarding][1].target[BlobID.Teaser] = [blobCenterX, subSubSub21BlobY, subSubSubBlobSize];
  result[BlobScene.OnBoarding][1].target[BlobID.Journey] = [blobCenterX, subSubSub21BlobY, hiddenBlobSize];
  result[BlobScene.OnBoarding][1].target[BlobID.VirtualWorld] = [blobCenterX, subSubSub21BlobY, hiddenBlobSize];
  result[BlobScene.OnBoarding][1].target[BlobID.Registration] = [blobCenterX, subSubSub21BlobY, hiddenBlobSize];
  
  result[BlobScene.OnBoarding][2].target[BlobID.Journey] = [blobCenterX, subSubSub22BlobY, subSubSubBlobSize];
  result[BlobScene.OnBoarding][2].target[BlobID.VirtualWorld] = [blobCenterX, subSubSub22BlobY, hiddenBlobSize];
  result[BlobScene.OnBoarding][2].target[BlobID.Registration] = [blobCenterX, subSubSub22BlobY, hiddenBlobSize];
  
  result[BlobScene.OnBoarding][3].target[BlobID.VirtualWorld] = [blobCenterX, subSubSub23BlobY, subSubSubBlobSize];
  result[BlobScene.OnBoarding][3].target[BlobID.Registration] = [blobCenterX, subSubSub23BlobY, hiddenBlobSize];

  result[BlobScene.OnBoarding][4].target[BlobID.Registration] = [blobCenterX, subSubSub24BlobY, subSubSubBlobSize];

  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ 

  function buildCloseAnimation(
    aboutConfinity: boolean,
    producatsServices: boolean, onBoarding: boolean,
    getInTouch: boolean, 
    toIdle: boolean
  ): any {
    // console.log({aboutConfinity,
    //   producatsServices, onBoarding,
    //   getInTouch, 
    //   toIdle})
    function combineBlobAnimTargetsWithReverseScene(combine: any[], scene: number) {
      for(let i=0; i<combine.length; i++) {
        let k = combine.length - 1 - i; // reverse 
        for(let j=BlobID.About; j<=BlobID.WorkForAs; j++) {
          let blobaTarget = result[scene][k].target[j];
          if(blobaTarget){
            combine[i].target[j] = blobaTarget;
          }
        }
      }

    }

    var anim: any = [];
    if(onBoarding) {
      for(let i=result[BlobScene.OnBoarding].length-1; i>=0; i--) { // create reverse base
        anim.push({
          time: result[BlobScene.OnBoarding][i].reverseTime,
          target: []
        });
      }
      combineBlobAnimTargetsWithReverseScene(anim, BlobScene.OnBoarding);
    }

    if(aboutConfinity || producatsServices || getInTouch) {
      let combine: any = [];
      for(let i=result[BlobScene.AboutConfinityX].length-1; i>=0; i--) {  // create reverse base
        combine.push({
          time: result[BlobScene.AboutConfinityX][i].reverseTime,
          timefunction: result[BlobScene.AboutConfinityX][i].reverseTimefunction,
          target: []
        });
      }

      if(aboutConfinity) {
        combineBlobAnimTargetsWithReverseScene(combine, BlobScene.AboutConfinityX);
      }
      if(producatsServices) {
        combineBlobAnimTargetsWithReverseScene(combine, BlobScene.ProductAndService);
      }
      if(getInTouch) {
        combineBlobAnimTargetsWithReverseScene(combine, BlobScene.GetInTouch);
      }
      anim = anim.concat(combine);
    }

    if(toIdle) {
      // add reverse menu animation
      for(let i=result[BlobScene.Menu].length-1; i>=0; i--) {
        anim.push({
          time: result[BlobScene.Menu][i].reverseTime,
          timefunction: result[BlobScene.Menu][i].reverseTimefunction,
          target: result[BlobScene.Menu][i].target
        });
      }
      ctx.isIdle = true;
    }
    return anim;
  }
  
  function getAnimation(sceneID: number, reverse: boolean): any {
    if(sceneID == BlobScene.Idle) {
      ctx.isIdle = true;
    } else {
      ctx.isIdle = false;
    }
    let animSequence: any = [];
    
    if (reverse) {
      for(let u=result[sceneID].length-1;u>=0;u--) {
        animSequence.push({
          time: result[sceneID][u].reverseTime,
          timefunction: result[sceneID][u].reverseTimefunction,
          target: result[sceneID][u].target
        })
      }
    } else {
      animSequence = result[sceneID];
    }
    return animSequence;
  }

  result["buildCloseAnimation"] = buildCloseAnimation;
  result["getAnimation"] = getAnimation;

  return result;
}

export default BlobConfigBuilder();
